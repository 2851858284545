import React from "react";
import "./faq.css";

const FAQ = () => {
  return (
    <section className="faq-section ">
      <div class="wrapper bg-white rounded shadow">
        <div class="h2 text-center fw-bold">Frequently Asked Questions</div>
        <br />
        <div
          class="accordion accordion-flush border-top border-start border-end"
          id="myAccordion"
        >
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                Who can become a PrepAI affiliate?
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse border-0"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#myAccordion"
            >
              <div className="pad">
                <span style={{ fontSize: "15px" }}>
                  Anyone with an interest in promoting our API solutions and
                  earning commissions can become a PrepAI affiliate. The program
                  is open to a diverse range of individuals, whether you have a
                  blog, website, or a strong online presence, becoming a PrepAI
                  affiliate is an accessible opportunity for those who want to
                  support the assessment revolution and make extra income.
                </span>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                What is the commission structure like?
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              class="accordion-collapse collapse border-0"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#myAccordion"
            >
              <div className="pad">
                <span style={{ fontSize: "15px" }}>
                  Our commission structure is designed to be transparent and
                  rewarding. You'll earn a commission for every new paid
                  subscriber/B2B customer you refer. The more referrals you
                  bring in, the more you earn. It's a simple and fair way to
                  maximize your earnings as an affiliate. Your commissions are
                  tied to our subscriptions and lifetime plans, ensuring that
                  you're fairly compensated for each new paying customer you
                  bring to PrepAI.
                </span>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                Is there a cost associated with joining the affiliate program?
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              class="accordion-collapse collapse border-0"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#myAccordion"
            >
              <div className="pad">
                <span style={{ fontSize: "15px" }}>
                  No, there is no registration/joining in the PrepAI affiliate
                  program. It's completely free, and you can start earning
                  commissions without any upfront expenses.
                </span>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
              >
                How can I promote PrepAI as an affiliate?
              </button>
            </h2>
            <div
              id="flush-collapseFour"
              class="accordion-collapse collapse border-0"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#myAccordion"
            >
              <div className="pad">
                <span style={{ fontSize: "15px" }}>
                  We provide you with a unique affiliate link that you can
                  incorporate into your blog posts, videos, articles, emails,
                  and various digital content channels. This link allows us to
                  track your referrals and ensure you receive your commissions
                  for successful leads.
                </span>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFive"
                aria-expanded="false"
                aria-controls="flush-collapseFive"
              >
                What kind of support can I expect as an affiliate?
              </button>
            </h2>
            <div
              id="flush-collapseFive"
              class="accordion-collapse collapse border-0"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#myAccordion"
            >
              <div className="pad">
                <span style={{ fontSize: "15px" }}>
                  As a PrepAI affiliate, you can count on our dedicated support
                  team to assist you in your affiliate journey. We offer
                  resources, training, and responsive assistance to help you
                  make the most of this opportunity and grow as an affiliate.
                  We're here to ensure your financial success.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
