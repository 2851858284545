import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { api, setAuthToken } from "../../../Api";
import { useDispatch } from "react-redux";
import "./Affiliate.css";
import { notifyError, notifySuccess } from "../../util/Toaster/Toaster";
import { setUser } from "../../../Redux/actions/userActions";
import { Button, Box, Modal, Typography } from "@material-ui/core";
import Notfound from "../../../commonComponents/Notfound";
import { withStyles } from "@material-ui/core/styles";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa6";

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#49bfa1 !important",
    textTransform: "capitalize",
    color: "#fff",
  },
  "&:hover": {
    backgroundColor: "#92C5FD",
  },
}))(Button);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Affiliate = (props) => {
  const [userStatusModal, setUserStatusModal] = useState(false);
  const [userStatus, setUserStatus] = useState({ userid: "", status: "" });

  const [users, setUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userType, setUserType] = useState("all"); // Default value for userType
  const [loggedUser, setLoggedUser] = useState(props.userData);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(true);
  const [searchParams, setSearchParams] = useState({
    fullName: "",
    sortBy: "DESC",
  });
  const [referralsCount, setReferralsCount] = useState([]);
  const [pagination, setPagination] = useState({
    page_no: 1,
    data_per_page: 10,
    filter_by_type: null,
  });
  const [totalData, setTotalData] = useState({
    total_count: 0,
    total_page: 1,
  });
  const [referalSort, setReferalSort] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchUsers = (is_referal_sort = false) => {
    const token = localStorage.getItem("loggedUserToken");
    setAuthToken(token);
    const { page_no, data_per_page, filter_by_type } = pagination;
    const params = {
      data_per_page,
      page_no,
      filter_by_type,
      is_referal_sort,
      ...searchParams,
    };
    setIsLoading(true);
    api
      .post("/users", params)
      .then((response) => {
        // notifySuccess("Data fetched successfully!")
        // const sortedUsers = response.data.users.sort((a, b) => {
        //   return new Date(b.createdAt) - new Date(a.createdAt);
        // });

        const { users, totalCount, count } = response.data;
        console.log(response.data);
        const total_page = Math.ceil(parseInt(totalCount) / data_per_page);
        if (total_page === 1 && pagination.page_no === 1) {
          setDisablePrev(true);
          setDisableNext(true);
        }
        if (total_page > 1 && pagination.page_no === 1) {
          setDisableNext(false);
          setDisablePrev(true);
        }
        setUsers(users);
        setReferralsCount(count);
        setTotalData({
          total_data: parseInt(totalCount),
          total_page,
        });
        // notifySuccess(response.data.message);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          notifyError(error.response.data.message);
          dispatch(setUser(null));
          navigate("/login");
        } else if (error.response) {
          notifyError(error.response.data.message);
        } else {
          notifyError("Something went wrong");
        }
        setIsLoading(false);
      });
  };

  const Reset = () => {
    setSearchParams({ fullName: "", sortBy: "DESC" });
    setPagination({
      page_no: 1,
      data_per_page: 10,
      filter_by_type: null,
    });
    setUserType("all");
  };

  useEffect(() => {
    fetchUsers(referalSort);
  }, [pagination, searchParams.sortBy]);

  useEffect(() => {
    if (searchParams.fullName == "") {
      fetchUsers(referalSort);
    }
  }, [searchParams.fullName]);

  const handleNextPage = async () => {
    const currentPage = pagination.page_no;
    const total_page = totalData.total_page;

    setDisablePrev(false);
    setPagination((prevState) => ({
      ...prevState,
      page_no: currentPage + 1,
    }));

    if (currentPage === total_page - 1) {
      setDisableNext(true);
    }
  };

  const handlePrevPage = async () => {
    const currentPage = pagination.page_no;
    setDisableNext(false);
    setPagination((prevState) => ({
      ...prevState,
      page_no: currentPage - 1,
    }));

    if (currentPage - 1 === 1) {
      setDisablePrev(true);
    }
  };

  const handleUserTypeChange = (event, user_id) => {
    const newUserType = event;
    const token = localStorage.getItem("loggedUserToken");

    setAuthToken(token);
    api
      .put(`/user/${user_id}`, { userType: newUserType })
      .then((response) => {
        // User type updated successfully
        const updatedUsers = users?.map((user) => {
          if (user.id === user_id) {
            return { ...user, userType: newUserType };
          }
          return user;
        });
        setUsers(updatedUsers);
        notifySuccess(response.data.message);
        const updatedLoggedUser = { ...loggedUser, userType: newUserType };
        dispatch({ type: "SET_LOGGED_USER", payload: updatedLoggedUser });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          notifyError(error.response.message);
          dispatch(setUser(null));
          navigate("/login");
        } else if (error.response.status === 404) {
          notifyError(error.response.message);
        }
        notifyError("Something went wrong, please try agian");
      });
  };

  const handleUserTypeFilter = (event) => {
    const value = event.target.value;
    setPagination((prevState) => ({
      ...prevState,
      page_no: 1,
      filter_by_type: value,
    }));
    setUserType(value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "IST",
    });
  };

  const csvHeaders = [
    { label: "ID", key: "user_id" },
    // { label: "Username", key: "userName" },
    { label: "Email", key: "email" },
    { label: "Name", key: "fullName" },
    { label: "Referrals", key: "referrals" },
    { label: "Affiliate Since", key: "createdAt" },
  ];

  const csvData = users?.map((user) => ({
    user_id: user.id,
    // userName: user.userName,
    email: user.email,
    fullName: user.fullName,
    referrals: user.referrals,
    createdAt: formatDate(user.createdAt),
  }));

  const getReferralsCount = (id) => {
    return referralsCount.find((itm) => itm.referral_id == id);
  };

  return (
    <div
      className="table-container affiliate"
      style={{ margin: "0 2.4rem", width: "auto" }}
    >
      {isLoading && (
        <div className="loading-container">
          <div className="loading"></div>
          <p>Loading...</p>
        </div>
      )}
      {!isLoading && (
        <>
          <h2 className="centered-heading">Affiliate Users</h2>
          <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename="affiliate_users.csv"
            className="export-button"
          >
            Export as CSV
          </CSVLink>

          <div className="sort-container">
            <label htmlFor="userType">Filter by User Type:</label>
            <select
              id="userType"
              value={userType}
              onChange={handleUserTypeFilter}
            >
              <option value="all">All</option>
              <option value="Pending">Pending</option>
              <option value="User">User</option>
              {/* <option value="Admin">Admin</option> */}
              <option value="Blocked">Blocked</option>
            </select>

            <div className="sort-container" style={{ marginBottom: 0 }}>
              <label htmlFor="leadStatus">Sort by:</label>
              <select
                id="leadStatus"
                value={searchParams.sortBy}
                onChange={(e) =>
                  setSearchParams({ ...searchParams, sortBy: e.target.value })
                }
              >
                <option value="DESC">Latest</option>
                <option value="ASC">Oldest</option>
              </select>
            </div>

            <div style={{ display: "flex", gap: "4px" }}>
              <input
                style={{
                  padding: "2px 4px",
                  borderRadius: "4px",
                  outline: "none",
                }}
                value={searchParams.fullName}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    fetchUsers(referalSort);
                  }
                }}
                placeholder="Full Name"
                name="fullName"
                onChange={(e) =>
                  setSearchParams({ ...searchParams, fullName: e.target.value })
                }
              />
              <ColorButton onClick={() => fetchUsers(referalSort)}>
                Search
              </ColorButton>
              <ColorButton onClick={() => Reset()}>Reset</ColorButton>
            </div>
          </div>
          {users?.length > 0 ? (
            <table className="styled-table">
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Referral ID</th>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Phone Numbner</th>
                  <th>Source</th>
                  <th>Country</th>
                  <th>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        columnGap: "5px",
                      }}
                    >
                      Referrals{" "}
                      {referalSort ? (
                        <FaSort
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            fetchUsers();
                            setReferalSort(false);
                          }}
                        />
                      ) : (
                        <FaSort
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            fetchUsers(true);
                            setReferalSort(true);
                          }}
                        />
                      )}
                    </p>
                  </th>
                  <th>Affiliate Since</th>
                  <th>User Status</th>
                </tr>
              </thead>
              <tbody>
                {users?.map((user, i) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.referral_id}</td>
                    <td>
                      <Link
                        style={{ color: "#49bfa1" }}
                        to={`/profile/${user.id}`}
                      >
                        {user.fullName}
                      </Link>
                    </td>
                    <td>{user.email}</td>
                    <td>{user?.mobile ? user?.mobile : "-"}</td>
                    <td>{user?.source ? user?.source : "-"}</td>
                    <td>{user?.country ? user?.country : "-"}</td>
                    <td>
                      {/* {getReferralsCount(user?.referral_id)?.referralCount ?? 0} */}

                      {user?.referralCount}
                    </td>
                    <td>{formatDate(user.createdAt)}</td>
                    <td>
                      <select
                        value={user.userType}
                        disabled={user.userType === "Admin"}
                        onChange={(event) => {
                          setUserStatusModal(true);
                          setUserStatus({
                            userid: user.id,
                            status: event.target.value,
                          });
                          // handleUserTypeChange(event, user.id)
                        }}
                      >
                        {user.userType === "Admin" && (
                          <option value="Admin">Admin</option>
                        )}
                        {user.userType === "User" ||
                        user.userType === "Admin" ? (
                          ""
                        ) : (
                          <option value="Pending">Pending</option>
                        )}

                        <option value="User">Active</option>

                        <option value="Blocked">Blocked</option>
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <Notfound />
          )}

          <div className="pagination-handler d-flex justify-content-end align-items-center">
            <button
              className="btn btn-outline-primary prepai-btn"
              onClick={handlePrevPage}
              disabled={disablePrev}
            >
              {"<"}
            </button>
            <span style={{ padding: "0px 10px" }}>{pagination.page_no}</span>
            <button
              className="btn btn-outline-primary prepai-btn"
              onClick={handleNextPage}
              disabled={disableNext}
            >
              {">"}
            </button>
          </div>
        </>
      )}
      <Modal
        open={userStatusModal}
        onClose={() => {
          setUserStatusModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={{ borderRadius: "7px" }} sx={style}>
          <Typography
            style={{ marginBottom: "2rem" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Are you sure you want to change the status on affiliate user ?
          </Typography>
          <div className="d-flex justify-content-end gap-2">
            <button
              className="btn btn-outline-primary prepai-btn"
              onClick={() => {
                setUserStatusModal(false);
              }}
            >
              Cancel
            </button>

            <button
              style={{
                background: "#49bfa1",
                color: "#fff",
                borderColor: "#49bfa1",
              }}
              className="btn btn-primary"
              onClick={() => {
                handleUserTypeChange(userStatus.status, userStatus.userid);
                setUserStatusModal(false);
              }}
            >
              Proceed
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Affiliate;
