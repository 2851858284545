import React, { useEffect, useState } from "react";
import { api, setAuthToken } from "../Api";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { notifyError, notifySuccess } from "./util/Toaster/Toaster";
import { setUser } from "../Redux/actions/userActions";
import TransactionTab from "./HomePage/Profile/TransactionTab";
import UpComingTransactionTab from "./HomePage/Profile/UpcomingTransactionTab";
import "./css/payouts.css";
import Footer from "./common/internalFooter";

const Payouts = () => {
  const [referrals, setReferrals] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const loggedUser = useSelector((state) => state.user.loggedUser);
  const [totalAmount, setTotalAmount] = useState("");
  const [payments, setPayments] = useState(null);
  const [upcomingPayments, setUpcomingPayments] = useState([]);
  const [paidAmount, setPaidAmount] = useState(0);

  const [paginationReferrals, setPaginationReferrals] = useState({
    page_no: 0,
    data_per_page: 2,
    total_pages: 0,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [pendingAmt, setPendingAmt] = useState(0);
  const [isEditing, setIsEditing] = useState(true);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  const [country, setCountry] = useState("");
  const [bankCountryName, setBankCountryName] = useState("");
  const [bankDetails, setBankDetails] = useState(null);

  useEffect(() => {
    const { fullName, email, userId, country } = loggedUser;
    setFullName(fullName);
    setEmail(email);
    setUserId(userId);
    setCountry(country);
    const fetchBankDetails = async () => {
      try {
        // setAuthToken(localStorage.getItem('loggedUserToken'));
        const response = await api.get(`/bank/${userId}`);
        const {
          bank_name,
          bank_code,
          branch_name,
          account_number,
          country,
          swift_code,
        } = response.data.bankDetails;
        setAccountNumber(account_number);
        setSwiftCode(swift_code ?? "");
        setIfscCode(bank_code);
        setBankName(bank_name);
        setBankBranch(branch_name);
        setBankDetails(response.data.bankDetails);
        // notifySuccess(response.data.message)
        setBankCountryName(country);
        // fetchUserDetails(dispatch);
        // Set other bank details as needed
      } catch (error) {
        if (error.response && error.response.status === 401) {
          notifyError(error.response.data.message);
          dispatch(setUser(null));
          navigate("/login");
        } else if (error.response) {
          // notifyError(error.response.data.message);
        } else {
          // notifyError("Something went wrong");
        }
      }
    };

    fetchBankDetails();
  }, [loggedUser]);

  const calculateTotalAmount = (payments) => {
    let total = 0;
    let totalTeamReferralCommision = 0;
    payments.forEach((payment) => {
      const amount_qualified = parseFloat(payment.commission_qualfied);
      const amount_converted = parseFloat(payment.commission_converted);
      if (!isNaN(amount_converted)) {
        total += amount_converted;
      }
      if (!isNaN(amount_qualified)) {
        total += amount_qualified;
      }
      if (payment.commission) {
        const total_team_referral = parseFloat(payment.commission);
        totalTeamReferralCommision += total_team_referral;
      }
    });
    setTotalAmount(total);
  };

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await api.get(`/payments/${loggedUser.userId}`);
        const paymentData = response.data.payments;

        const upcomingpaymentData = response.data.upcoming_transaction_ps_data;
        // notifySuccess(response.data.message);
        if (Array.isArray(paymentData)) {
          if (paymentData.length === 0) {
            setPayments([]);
            setPaidAmount(0.0); // Set the total amount to 0 if there are no payments
          } else {
            setPayments(paymentData);
            calculateTotalPaidAmount(paymentData);
            calculateTotalAmount(response.data.upcoming_transaction_ps_data);
          }
        } else if (typeof paymentData === "object") {
          setPayments([paymentData]);
          calculateTotalPaidAmount([paymentData]);
        }
        setUpcomingPayments(upcomingpaymentData?.filter(itm => itm?.commission_converted > 0 && itm?.payment_status === 0 ));
        let pendingAmount = 0;
        response.data.upcoming_transaction_ps_data?.map((itm) => {
          if (itm?.payment_status === 0) {
            pendingAmount += Number(itm?.commission_converted);
          }
        });
        setPendingAmt(pendingAmount);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          notifyError(error.response.data.message);
          dispatch(setUser(null));
          navigate("/login");
        } else if (error.response) {
          notifyError(error.response.data.message);
        } else {
          notifyError("Something went wrong");
        }
      }
    };

    // Function to calculate the total amount
    const calculateTotalPaidAmount = (payments) => {
      let total = 0;

      payments.forEach((payment) => {
        const amount = parseFloat(payment.amount);
        if (!isNaN(amount)) {
          total += amount;
        }
      });

      setPaidAmount(total);
    };

    fetchPayments();
  }, []);

  const handleEditClick = () => {
    // navigate(`/profile/?isEdit=1`);
    window.location.href = "/profile/?isEdit=1";
  };

  return (
    <div className="" style={{ minHeight: "90vh !important" }}>
      <div className="up-container">
        <h1 className="page-title">Payouts</h1>
        {/* <StyledTabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label=""
        >
          <StyledTab label="Transaction Details" />
        </StyledTabs> */}
      </div>

      <div className="mb-3 mt-5" style={{ display: "flex" }}>
        <div className="payout-cards" style={{ margin: "0 1.2rem" }}>
          <div>
            <div className="bottom-lines">
              <p className="amount-desc" style={{ textAlign: "left" }}>
                Total Commission Earned :
                <span className="amount">${paidAmount.toFixed(2)}</span>
              </p>
            </div>
            <div className="bottom-lines">
              <p className="amount-desc" style={{ textAlign: "left" }}>
                Remaining Balance:{" "}
                <span className="amount">${pendingAmt.toFixed(2)}</span>
              </p>
            </div>
          </div>
        </div>
        <div
          style={{ maxWidth: "100%", margin: "0 1.2rem" }}
          className="payout-cards"
        >
          <div style={{ display: "flex" }}>
            <h2>Bank Details</h2>
            <FontAwesomeIcon
              onClick={() => handleEditClick()}
              style={{
                fontSize: "21px",
                margin: 0,
                cursor: "pointer",
                paddingLeft: "10px",
              }}
              icon={faPenToSquare}
            />
          </div>
          <div className="profile-field">
            <label>Account Number:</label>
            <span>{accountNumber}</span>
          </div>
          {ifscCode !== "" && (
            <div className="profile-field">
              <label>IFSC Code:</label>
              <span>{ifscCode}</span>
            </div>
          )}

          {swiftCode !== "" && (
            <div className="profile-field">
              <label>Swift Code:</label>
              <span>{swiftCode}</span>
            </div>
          )}

          <div className="profile-field">
            <label>Bank Name:</label>
            <span>{bankName}</span>
          </div>
          <div className="profile-field">
            <label>Bank Address:</label>
            <span>{bankBranch}</span>
          </div>
          <div className="profile-field">
            <label>Country:</label>
            <span>{bankCountryName}</span>
          </div>
        </div>
      </div>
      <div style={{ padding: "20px" }}>
        <h3 style={{ textAlign: "center" }}>Transactions</h3>
        <TransactionTab transections={payments} />
      </div>
      <div style={{ padding: "20px" }}>
        <h3 style={{ textAlign: "center" }}>Upcoming Transactions</h3>
        <UpComingTransactionTab transections={upcomingPayments} />
      </div>
      <br />
      <br />
      <Footer></Footer>
    </div>
  );
};

export default Payouts;
