import React from "react";

let data = [
  {
    id: 1,
    instance: "When a user refers up to 10 new paid subscribers",
    subscriptionPlan: "Subscription/Lifetime",
    logicType: "Direct Payment",
    commission: "10%",
    condition: "Pricing in USD",
  },
  {
    id: 2,
    instance: "When a user refers more than 10 and up to 20 paid subscribers",
    subscriptionPlan: "Subscription/Lifetime",
    logicType: "Direct Payment",
    commission: "12%",
    condition: "Pricing in USD",
  },
  {
    id: 3,
    instance: "When a user refers more than 20 and up to 30 paid subscribers",
    subscriptionPlan: "Subscription/Lifetime",
    logicType: "Direct Payment",
    commission: "15%",
    condition: "Pricing in USD",
  },
  {
    id: 4,
    instance: "When a user refers more than 30 paid subscribers",
    subscriptionPlan: "Subscription/Lifetime",
    logicType: "Direct Payment",
    commission: "20%",
    condition: "Pricing in USD",
  },
  // {
  //   id: 5,
  //   instance: "When a user refers upto 5 B2B customers those who convert",
  //   subscriptionPlan: "Custom/API",
  //   logicType: "API Customer",
  //   commission: "10%",
  //   condition: "Custom Quote",
  // },
  // {
  //   id: 6,
  //   instance: "When a user refers more than 5 B2B customers those who convert",
  //   subscriptionPlan: "Custom/API",
  //   logicType: "API Customer",
  //   commission: "20%",
  //   condition: "Custom Quote",
  // },
  // {
  //   id: 7,
  //   instance: "When a user refers up to 5 qualified customers who convert",
  //   subscriptionPlan: "Custom/API",
  //   logicType: "B2B Customer",
  //   commission: "20%",
  //   condition: "More than 10k",
  // },
  // {
  //   id: 8,
  //   instance: "When a user refers more than 5 qualified customers who convert",
  //   subscriptionPlan: "Custom/API",
  //   logicType: "B2B Customer",
  //   commission: "30%",
  //   condition: "More than 10k",
  // },
];

export const PrepaiTable = ({ newBoxShadow, width }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ width: "100%" }}
    >
      <table
        className="styled-table text-center"
        style={{
          width: width,
          boxShadow: newBoxShadow ? "0px 0px 1px 0px" : "",
        }}
      >
        <thead style={{ fontSize: "13px" }}>
          <tr>
            <th style={{ fontWeight: "500" }} className="text-center">
              Sr.No.
            </th>
            <th style={{ fontWeight: "500" }} className="text-center">
              Instance (Only for New Customers)
            </th>
            <th style={{ fontWeight: "500" }} className="text-center">
              Plan Type
            </th>
            {/* <th style={{ fontWeight: "500" }} className="text-center">
              Logic Type
            </th> */}
            <th style={{ fontWeight: "500" }} className="text-center">
              Commission % (One Time)
            </th>
            {/* <th style={{ fontWeight: "500" }} className="text-center">
              Condition 
            </th> */}
          </tr>
        </thead>
        <tbody>
          {data?.map((itm, i) => (
            <tr
              key={itm?.id}
              style={{
                fontSize: "13px",
                background: i % 2 !== 0 ? "#fcfcfc" : "#fff",
              }}
            >
              <td>{itm.id}</td>
              <td>{itm.instance}</td>
              <td>{itm.subscriptionPlan}</td>
              {/* <td>{itm.logicType}</td> */}
              <td>{itm.commission}</td>
              {/* <td>{itm.condition}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PrepaiTable;
